import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from './index';
import type {
  InterestInfoItem,
  InterestInfo,
  InterestMonthTotalAmount,
  SpecTreeTable,
  ReportResType,
  VersionType
} from '@/views/Closing/type';

type YearTpye = {
  year: string;
  month: string;
  showYear: string;
};
// 获取服务器时间
export const getLastMonth = (): Promise<string> => {
  return fetchGet('/pcapi/closing/baseSetting/lastMonth');
};
export const getCurrentYear = (): Promise<YearTpye> => {
  return fetchGet('/pcapi/api/v1/homepage/currYear');
};
// 获取make基本信息
export const getMakeInfos = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/homepage/monthlyClosingByMake', { params });
};
// 获取make基本信息
export const getNewMake = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/baseSetting/closingMonth', { params });
};
// 获取 data pre 信息
export const getDataPre = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/collectInterfaceData/result', { params });
};
// PBP查询接口
export const getPBPData = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/adjustment', { params });
};
// PBP保存接口
export const savePBPData = (params = {}): Promise<any> => {
  return fetchPostPic('/pcapi/closing/dataPreparation/adjustment', params);
};
export const postUpload = (uploadConfig = {}, data = { make: '', month: '', mcid: -1 }, params: any): Promise<any> => {
  return fetchPostPic(
    `/pcapi/closing/dataPreparation/upload?make=${data.make}&month=${data.month}&mcid=${data.mcid}`,
    params,
    uploadConfig
  );
};
// PBP聚合计算
export const computePBPData = (params = {}): Promise<any> => {
  return fetchGetMess('/pcapi/closing/dataPreparation/adjustment/compute', { params });
};
// Data Preparation 数据导入
export const importData = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/collectInterfaceData', { params });
};
/**
 * Update IMPACT Vehicle
 */
export const importImpact = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/validateImpact', { params });
};
// 校验NST Group
export const checkNSTGroup = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/validateNSTGroup', { params });
};
// Closing Maintain接口
export const getNstGroupLeftList = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/modelMaintain/getUnmatchModel', { params });
};
// Closing Maintain search接口
export const searchList = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/modelMaintain/searchMatchData', { params });
};
// Closing Maintain 批量校验接口
export const checkModel = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/modelMaintain/validateModels', { params });
};
// Closing Maintain 保存接口
export const saveModel = (params = {}): Promise<any> => {
  return fetchPost('/pcapi/closing/dataPreparation/modelMaintain/saveMatchData', params);
};

// Blue Invoice 获取tree数据
export const getTreeList = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/blueInvoice/allocation/tree', { params });
};
// Blue Invoice 获取Price ADJ或者BBAC列表 的Direct Sales数据
export const getDirectList = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/blueInvoice/allocation/nocategory', { params });
};
// 获取category信息
export const getCategory = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/blueInvoice/category', { params });
};
// 上传category信息
export const postCategory = (params = {}): Promise<any> => {
  return fetchPost('/pcapi/closing/blueInvoice/category', { params });
};
// 导出category信息
export const exportCategory = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/blueInvoice/category/export', { params });
};
// 下载模板
export const downTemplate = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/blueInvoice/category/template', { params });
};
// 获取Overview信息
export const getOverview = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/blueInvoice/overview/get', { params });
};

// 获取SubOverview信息
export const getSubOverview = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/blueInvoice/overview/getSubInfo', { params });
};

// 上传Blue Invoice Car Categery
export const uploadBlueInvoiceCategory = (params: { mcId: number }, data: any, uploadConfig: object): Promise<any> => {
  return fetchPostPic('/pcapi/closing/blueInvoice/category', data, Object.assign({ params: params }, uploadConfig));
};

// 上传Blue Invoice Car Categery
export const getTopic = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/common/getUserPrompts', { params });
};

// 计算按钮接口
export const getCalculate = (params = {}): Promise<any> => {
  return fetchGetMess('/pcapi/closing/dataPreparation/calculate', { params });
};

// 获取PMT Closing Rule
export const getPmtRoleInfo = () => {
  return fetchGet('/pcapi/closing/pmt/rule/info');
};

// 保存PMT Closing Rule
export const savePmtRoleInfo = (params = {}) => {
  return fetchPost('/pcapi/closing/pmt/rule/save', params);
};

// program 表格信息获取接口
export const getTableInfo = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/program/info', { params });
};

// 获取interest数据
export const getInterestInfo = (params = {}): Promise<InterestInfo> => {
  return fetchGet('/pcapi/closing/interest/info', { params });
};

// 获取interest total llp
export const getInterestMonthAmountInfo = (closingId: number): Promise<InterestMonthTotalAmount> => {
  return fetchGet('/pcapi/closing/interest/monthTotalAmount', { params: { closingId } });
};
// 获取interest total llp
export const saveInterestInfo = (params: InterestInfoItem[]) => {
  return fetchPost('/pcapi/closing/interest/batchSave', params);
};

// program 表格信息保存接口
export const saveTableInfo = (params = {}): Promise<any> => {
  return fetchPostPic('/pcapi/closing/program/batchSave', params);
};

// program init初始化接口
export const initProgramInfo = (params: any): Promise<any> => {
  return fetchPostPic(`/pcapi/closing/program/init?mcId=${params}`);
};

// 获取program status状态接口
export const getProgramStatus = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/program/status', { params });
};

// 获取MSRP校验状态接口
export const checkMSRP = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/validateMsrp', { params });
};
// Specil Adj Sub Info
export const getSubInfo = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/allocation/overview/getSubInfo', { params });
};

// Specil Adj Brand
export const getSpecBrand = (params = {}): Promise<string[]> => {
  return fetchGet('/pcapi/api/v1/allocation/getBrandCategory', { params });
};

// Specil Adj Topic & Name
export const getTopicName = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/allocation/getNameAndTopicList', { params });
};

// Specil Adj TreeTable
export const getTreeTable = (params = {}): Promise<SpecTreeTable> => {
  return fetchGet('/pcapi/api/v1/allocation/searchTree', { params });
};

// Specil Adj AdjustmentNo
export const getAdjustmentNo = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/allocation/overview/getMaxAdjustmentNo', { params });
};

// Specil Adj Save
export const postSaveData = (params = {}): Promise<any> => {
  return fetchPost('/pcapi/api/v1/allocation/save', params);
};

// IMPACT 通过nstGroupMapping批量获取IMPACT CODE的接口
export const getNstGroupList = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/closing/dataPreparation/getAllNSTGroup', { params });
};

// Report Mapping Set获取年列表
export const getYearOptions = (): Promise<any> => {
  return fetchGetMess('/pcapi/report/item/mapping/years');
};

// Report Mapping Set获取列表数组
export const getTreeData = (year: string): Promise<any> => {
  return fetchGetMess('/pcapi/report/item/mapping/tree?year=' + year);
};
// Report Mapping Set获取列表项下拉
export const getSelectItemTreeList = (): Promise<any> => {
  return fetchGetMess('/pcapi/report/structure/tree');
};
// Report Mapping Set 列表保存
export const postSaveTreeData = (params = {}): Promise<any> => {
  return fetchPostPic('/pcapi/report/item/mapping/update', params);
};
// 设置报表版本
export const postSaveVersion = (params = {}): Promise<any> => {
  return fetchPostPic('/pcapi/api/v1/reportData/setReportVersion', params);
};
// final view button save(新接口 2021/10/29)
export const postFinalSave = (params: number): Promise<any> => {
  return fetchPostPic(`/pcapi/api/v1/reportData/saveWipVersion2FianlVersion?mcId=${params}`);
};
// final view button enable(新接口 2021/10/29)
export const postFinalEnable = (params: number): Promise<any> => {
  return fetchPostPic(`/pcapi/api/v1/reportData/enable?mcId=${params}`);
};
// final view button edit(新接口 2021/10/29)
export const postFinalEdit = (params: number): Promise<any> => {
  return fetchPostPic(`/pcapi/api/v1/reportData/getMcIds?mcId=${params}`);
};
// 获取报表brand
export const getBrandList = (params = {}): Promise<string[]> => {
  return fetchGet('/pcapi/api/v1/reportData/brandList', { params });
};
export const seachVersion = (params = {}): Promise<VersionType> => {
  return fetchGet('/pcapi/api/v1/reportData/searchReportVersion', { params });
};
// 生成报表
export const generateReport = (params = {}): Promise<any> => {
  return fetchPostPic('/pcapi/api/v1/reportData/generateReport', params);
};
// Report 1 报表
export const getReportOverview = (params = {}): Promise<ReportResType> => {
  return fetchGet('/pcapi/api/v1/reportData/getOverview', { params });
};
// Report 2 报表
export const getReport2 = (params = {}): Promise<ReportResType> => {
  return fetchGet('/pcapi/api/v1/reportData/getOverviewByBrand', { params });
};
// Report 3 报表
export const getReport3 = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/reportData/getOverviewByBrandAndL1', { params });
};
// Report l1Structure
export const getl1Structure = (): Promise<string[]> => {
  return fetchGet('/pcapi/api/v1/reportData/l1Structure');
};
// Report 4 报表
export const getReport4 = (params = {}): Promise<ReportResType> => {
  return fetchGet('/pcapi/api/v1/reportData/getOverviewByDemoCar', { params });
};
/**
 * homepage页面CBU上传
 */
export const uploadImpactActual = (param: number, data: any, uploadConfig: object): Promise<any> => {
  return fetchPostPic(`/pcapi/api/v1/reportData/uploadCBUAdjustExcel?mcId=${param}`, data, uploadConfig);
};

/**
 * homepage lock
 */
export const getLock = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/homepage/toggleLock', { params });
};
/**
 * Final View wsVersion
 */
export const getWsVersion = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/planning/basicInfo/getPlanningYear', { params });
};
/**
 * 获取blue invoice锁信息
 */
export const getLockinfos = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/homepage/closing/lockInfo', { params });
};
// 设置blue invoice锁
export const setBlueinvoiceLock = (params: number): Promise<any> => {
  return fetchPostPic(`/pcapi/closing/blueInvoice/status/set?mcId=${params}&blueInvoiceLockStatus=false`);
};
/**
 * Dealer Stock
 */
export const getCarculation = (params: any): Promise<any> => {
  return fetchGet(`/pcapi/api/v1/dealerStockAllocation/getDSForTab1`, { params });
};
export const saveCalculation = (params: any): Promise<any> => {
  return fetchPostPic(`/pcapi/api/v1/dealerStockAllocation/saveTab1Avg`, params);
};
export const getAllocationLeft = (params: any): Promise<any> => {
  return fetchGet(`/pcapi/api/v1/dealerStockAllocation/getDSForTab2Left`, { params });
};
export const getAllocationRight = (params: any): Promise<any> => {
  return fetchGet(`/pcapi/api/v1/dealerStockAllocation/getDSForTab2Right`, { params });
};
export const saveAllocation = (params: any): Promise<any> => {
  return fetchPostPic(`/pcapi/api/v1/dealerStockAllocation/saveTab2Allocation`, params);
};
export const dealerStockAccess = (params: any): Promise<any> => {
  return fetchGetMess(`/pcapi/api/v1/dealerStockAllocation/access`, { params });
};
export const nextYearDataForClosing = (mcId: number) => {
  return fetchGet('/pcapi/planning/basicInfo/nextYearDataForClosing', {
    params: {
      mcId
    }
  });
};
export const resetAvg = (mcId: number, planningId: number) => {
  return fetchPost('/pcapi/api/v1/dealerStockAllocation/resetAvg', {
    mcId,
    planningId
  });
};
//bbac impact mapping文件上传
export const uploadMapping = (param: number, data: any, uploadConfig: object): Promise<any> => {
  return fetchPostPic(`/pcapi/closing/bbacImpactMapping/upload?mcId=${param}`, data, uploadConfig);
};

//export按钮置灰
export const checkExporting = (mcId: number) => {
  return fetchGet(`/pcapi/closing/bbacImpactMapping/checkIsUploaded?mcId=${mcId}`);
};
