
import { defineComponent, ref } from 'vue'
import { nextYearDataForClosing } from '@/API/closing' 
export default defineComponent({
  props: {
    makeId: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const nextYearInfo = ref<any[]>([])

    const planningList = ref<any []>([])
    const kufriList = ref<any []>([])
    const yearList = ref<any []>([])

    const planningName = ref<string>('')
    const nextYear = ref<string>('')
    const planningId = ref<string>('')

    const handleChangePlanningName = (value: string) => {
      yearList.value = [...new Set(nextYearInfo.value.filter((item: any) => item.planningName === value).map((item: any) => item.year))]
      nextYear.value = ''
      planningId.value = ''
    }

    const handleChangeNextYear = (value: string) => {
      kufriList.value = [...new Set(nextYearInfo.value.filter((item: any) => item.planningName === planningName.value && item.year === value)
        .map((item: any) => {
          return {
            id: item.id,
            name: item.kufriMonth
          }
        }))]
      planningId.value = ''
    }

    const handleConfirm = () => {
      if (planningId.value) {
        emit('confirm', planningId.value)
      }
    }

    nextYearDataForClosing(props.makeId).then((data: any) => {       
      if (data) {
        nextYearInfo.value = data
        planningList.value = [...new Set(data.filter((item: any) => item.planningName).map((item: any) => item.planningName))]
      }
    })

    return {
      planningList,
      kufriList,
      yearList,
      planningName,
      nextYear,
      planningId,
      handleChangePlanningName,
      handleChangeNextYear,
      handleConfirm
    }
  }
})
